class EmployeeService {
  get_employees(page) {
    return axios.get('employees?page=' + page)
  }
  edit(id) {
    return axios.get('employees/edit/' + id)
  }
  store(data) {
    return axios.post('employees/store', data)
  }
  update(id, data) {
    return axios.post('employees/update/' + id, data)
  }
  destroy(id) {
    return axios.delete('employees/delete/' + id)
  }

}
export default new EmployeeService();