
class DailyEntryService {
  get_daily_entry(page, data) {
    return axios.post('daily_entry?page=' + page, data)
  }
  edit(id) {
    return axios.get('daily_entry/edit/' + id)
  }
  store(data) {
    return axios.post('daily_entry/store', data)
  }
  update(id, data) {
    return axios.post('daily_entry/update/' + id, data)
  }
  destroy(id) {
    return axios.delete('daily_entry/delete/' + id)
  }
  create() {
    return axios.get('daily_entry/create')
  }
  details(id) {
    return axios.get('daily_entry/details/' + id)
  }
  get_data_by_project(id) {
    return axios.get('projects/get-data-by-project/' + id)
  }

  get_employee_by_project(id) {
    return axios.get('projects/get-employee-by-project/' + id)
  }

}
export default new DailyEntryService();