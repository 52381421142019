import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const projects = {
    path: "/",
    component: DashboardLayout,
    name: "Project-Managment",
    children: [{
        path: "/projects",
        name: "Projects",
        component: () =>
            import("@/views/Current/Projects/index.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'project-list',
        },
    },
    {
        path: "/projects/create",
        name: "CreateProject",
        component: () =>
            import("@/views/Current/Projects/create.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'project-create',
        },
    },
    {
        path: "/projects/edit/:id",
        name: "UpdateProject",
        component: () =>
            import("@/views/Current/Projects/update.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'project-edit',
        },
    },

    {
        path: "/projects/requests/:id",
        name: "UpdateProject",
        component: () =>
            import("@/views/Current/Projects/daily_entry.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'project-edit',
        },
    },

    {
        path: "/projects/assign-employee/:id",
        name: "AssignEmployee",
        component: () =>
            import("@/views/Current/Projects/assign_employee.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            // permissions: '',
            permissions: 'project-assign-employee',
        },
    },

    {
        path: "/projects/details/:id",
        name: "DetailsProject",
        component: () =>
            import("@/views/Current/Projects/details.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            // permissions: '',
            permissions: 'project-details',
        },
    },

    {
        path: "/projects/daily-entry/:id",
        name: "DailyEntryProject",
        component: () =>
            import("@/views/Current/Projects/daily_entry.vue"),
        meta: {
            groupName: "Projects",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            // permissions: '',
            permissions: 'project-daily-entry',
        },
    },

    ]
}


export const daily_entry = {
    path: "/",
    component: DashboardLayout,
    name: "daily-entry-Managment",
    children: [{
        path: "/daily-entry",
        name: "daily_entry",
        component: () =>
            import("@/views/Current/daily_entry/index.vue"),
        meta: {
            groupName: "daily_entry",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'delaiy-entries-index',
        },
    },
    {
        path: "/daily-entry/create",
        name: "CreateDailyEntry",
        component: () =>
            import("@/views/Current/daily_entry/create.vue"),
        meta: {
            groupName: "daily_entry",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'delaiy-entries-create',
        },
    },
    {
        path: "/daily-entry/edit/:id",
        name: "UpdateDailyEntry",
        component: () =>
            import("@/views/Current/daily_entry/update.vue"),
        meta: {
            groupName: "daily_entry",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'delaiy-entries-edit',
        },
    },

    ]
}

export const requests = {
    path: "/",
    component: DashboardLayout,
    name: "requests-Managment",
    children: [{
        path: "/requests",
        name: "Requests",
        component: () =>
            import("@/views/Current/Requests/index.vue"),
        meta: {
            groupName: "requests",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'request-index',
        },
    },
    {
        path: "/requests/create",
        name: "CreateRequest",
        component: () =>
            import("@/views/Current/Requests/create.vue"),
        meta: {
            groupName: "requests",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'request-create',
        },
    },
    {
        path: "/requests/edit/:id",
        name: "UpdateRequest",
        component: () =>
            import("@/views/Current/Requests/update.vue"),
        meta: {
            groupName: "requests",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'request-edit',
        },
    },
    {
        path: "/requests/details/:id",
        name: "DetailsRequest",
        component: () =>
            import("@/views/Current/Requests/details.vue"),
        meta: {
            groupName: "requests",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'request-edit',
        },
    },

    ]
}