
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const roles = {
    path: "/",
    component: DashboardLayout,
    name: "Roles-Managment",
    children: [{
        path: "/roles",
        name: "Roles",
        component: () =>
            import("@/views/Current/Roles/index.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-role-list',
        },
    },
    {
        path: "/roles/create",
        name: "Create Role",
        component: () =>
            import("@/views/Current/Roles/create.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'role-create',
        },
    },
    {
        path: "/roles/:id",
        name: "Update Role",
        component: () =>
            import("@/views/Current/Roles/update.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'role-edit',
        },
    },

    ]
}

export const users = {
    path: "/",
    component: DashboardLayout,
    name: "Users-Managment",
    children: [{
        path: "/users",
        name: "Users",
        component: () =>
            import("@/views/Current/Users/index.vue"),
        meta: {
            groupName: "Users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-list',
        },
    },
    {
        path: "/users/create",
        name: "CreateUser",
        component: () =>
            import("@/views/Current/Users/create.vue"),
        meta: {
            groupName: "Users",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-create',
        },
    },


    ]
}

export const employee = {
    path: "/",
    component: DashboardLayout,
    name: "Employee-Managment",
    children: [{
        path: "/employees",
        name: "Employee",
        component: () =>
            import("@/views/Current/Employee/index.vue"),
        meta: {
            groupName: "Employee",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-employee-list',
        },
    },
    {
        path: "/employees/create",
        name: "CreateEmployee",
        component: () =>
            import("@/views/Current/Employee/create.vue"),
        meta: {
            groupName: "Employee",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'employee-create',
        },
    },
    {
        path: "/employees/edit/:id",
        name: "UpdateEmployee",
        component: () =>
            import("@/views/Current/Employee/update.vue"),
        meta: {
            groupName: "Employee",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'employee-edit',
        },
    },

    ]
}


export const contractors = {
    path: "/",
    component: DashboardLayout,
    name: "Contractors-Managment",
    children: [{
        path: "/contractors",
        name: "Contractors",
        component: () =>
            import("@/views/Current/Contractors/index.vue"),
        meta: {
            groupName: "Contractors",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-contractors-list',
        },
    },
    {
        path: "/contractors/create",
        name: "CreateContractor",
        component: () =>
            import("@/views/Current/Contractors/create.vue"),
        meta: {
            groupName: "Contractors",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'contractors-create',
        },
    },
    {
        path: "/contractors/edit/:id",
        name: "UpdateContractor",
        component: () =>
            import("@/views/Current/Contractors/update.vue"),
        meta: {
            groupName: "Contractors",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'contractors-edit',
        },
    },

    ]
}
