export const admin_report = {
    action: "mdi-file-chart",
    title: "Reports",
    permission: "report",
    items: [

        {
            prefix: "O",
            title: "dailyReport",
            link: "/reports/daily",
            permission: "report-daily-index",
        },
        {
            prefix: "O",
            title: "monthlyProjectReport",
            link: "/reports/projects",
            permission: "report-monthly-project",
        },
        {
            prefix: "O",
            title: "monthlyEmployeeReport",
            link: "/reports/employee",
            permission: "report-monthly-employee",
        },

    ]
}

export const contractor_report = {
    action: "mdi-file-chart",
    title: "Reports",
    permission: "report",
    items: [

        {
            prefix: "O",
            title: "dailyReport",
            link: "/reports/daily",
            permission: "",
        },
        // {
        //     prefix: "O",
        //     title: "dailyReport",
        //     link: "/reports/",
        //     permission: "",
        // },


    ]
}