export const users = {
    action: "fa fa-user",
    title: "Users",
    permission: "user",
    items: [
        {
            prefix: "O",
            title: "Roles",
            link: "/roles",
            permission: "user-role-list",
        },
        {
            prefix: "O",
            title: "Users",
            link: "/users",
            permission: "user-list",
        },
        {
            prefix: "O",
            title: "employees",
            link: "/employees",
            permission: "user-employee-list",
        },
        {
            prefix: "O",
            title: "contractors.contractors",
            link: "/contractors",
            permission: "user-contractors-list",
        },
    ]
}