// import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function auth({
    nextMiddleware,
    router,
    store,
    to,
    from,
    contextNext,
}) {
    if (!store.state.auth.loggedIn) {
        // alert(1)
        // saveRedirectionIntoStorage(to.fullPath);
        return contextNext({
            path: "/login",
        });
    } else {
        // alert(2)

        // console.log(store.state.auth.user.status)
        if (
            store.state.auth.user &&
            store.state.auth.user.status != 1
        ) {
            return contextNext({
                path: "/login",
            });
        }
        return nextMiddleware();
    }
}
