<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div class="login">
        <div class="row mb-0">
          <div class="col-md-4 mx-auto pb-0">
            <div class="">
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <!-- your content here -->
                <router-view></router-view>
              </fade-transition>
            </div>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      lang: "",
      abbr: "",
      language: [
        { title: '<span class="font-weight-bold">NI</h6>', abbr: "ni" },
        { title: '<span class="font-weight-bold">AR</h6>', abbr: "ar" },
      ],
    };
  },
  methods: {
    change_lang() {
      if (this.abbr == "ar") {
        this.lang = this.$i18n.t("general.English") + " (NI)";
        this.abbr = "ni";
        localStorage.setItem("lang", "ar");
      } else {
        this.lang = this.$i18n.t("general.Arabic") + " (AR)";
        this.abbr = "ar";
        localStorage.setItem("lang", "ni");
      }
      // alert(this.abbr);
      window.location.reload();
    },
  },
  mounted() {
    // if (localStorage.getItem("lang") == "ar") {
    //   this.abbr = "ni";
    //   this.lang = this.$i18n.t("general.English") + " (NI)";
    // } else if (localStorage.getItem("lang") == "ni") {
    //   this.abbr = "ar";
    //   this.lang = this.$i18n.t("general.Arabic") + " (AR)";
    // }
  },
  created() {
    if (localStorage.getItem("lang") == "ar") this.$vuetify.rtl = true;
    else this.$vuetify.rtl = false;
  },
  beforeDestroy() {
    this.$vuetify.rtl = false;
  },
};
</script>
<style lang="css">
html:lang(ar) .section-form {
  direction: rtl;
  /* text-align: right; */
}
</style>
