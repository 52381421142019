<template>
  <v-app-bar
    :color="background"
    height="60"
    class="mt-5 px-0 mx-4 border-radius-lg toolbar-content-padding-y-none"
    :class="navbarFixed ? 'position-sticky blur top-1 z-index-sticky py-2' : ''"
    rounded
    elevation="0"
  >
    <div class="d-flex justify-space-between align-center" style="width: 100%">
      <!--   Drawer Toggler   -->
      <div
        class="drawer-toggler pa-5 ms-6 cursor-pointer"
        style="padding-right: 0 !important"
        :class="{ active: togglerActive }"
        @click="minifyDrawer"
      >
        <div class="drawer-toggler-inner">
          <i class="drawer-toggler-line bg-body"></i>
          <i class="drawer-toggler-line bg-body"></i>
          <i class="drawer-toggler-line bg-body"></i>
        </div>
      </div>
      <div>
        <v-menu
          transition="slide-y-transition"
          offset-y
          min-width="150"
          max-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body mx-5"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="16">fa fa-globe text-sm</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item
              v-for="(item, i) in language"
              :key="i"
              class="px-4 py-2 list-item-hover-active"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col @click="change_lang(item.abbr)">
                      <h6 class="text-sm font-weight-normal mb-1 text-typo">
                        {{ item.title }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--   User Dropdown   -->
        <v-menu
          transition="slide-y-transition"
          offset-y
          min-width="250"
          max-width="250"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-body btn-hover box-shadow-none"
              :ripple="false"
              color="transparent"
              style="box-shadow: none; padding: 0"
              v-bind="attrs"
              v-on="on"
              v-if="user"
            >
              <div class="d-flex flex-column align-center">
                <span>{{ fullName }}</span>
                <span class="text-center"> {{ accountType }} </span>
              </div>
            </v-btn>
          </template>

          <!-- here -->
          <v-list class="py-0">
            <v-list-item class="pa-4 list-item-hover-active">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <router-link to="/profile" style="text-decoration: none">
                        <h6 class="text-sm font-weight-normal mb-1 text-typo">
                          <v-icon size="16">fa fa-user me-sm-2 text-sm</v-icon>
                          {{ $t("general.profile") }}
                        </h6>
                      </router-link>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item @click="logout" class="pa-4 list-item-hover-active">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  <v-row>
                    <v-col>
                      <h6 class="text-sm font-weight-normal mb-1 text-typo">
                        <v-icon size="16"
                          >fas fa-sign-out-alt me-sm-2 text-sm</v-icon
                        >
                        {{ $t("user.logout") }}
                      </h6>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
// import CustomBreadcrumb from "@/components/CustomBreadcrumb";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "app-bar",
  // components: { CustomBreadcrumb },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      types: [
        "raft_office",
        "raft_company",
        "service_provider",
        "kdana",
        "admin",
      ],
      count: 1,
      language: [
        { title: "NI", abbr: "ni" },
        { title: "AR", abbr: "ar" },
      ],
    };
  },
  computed: {
    ...mapState("auth", ["user", "type"]),
    ...mapState("notifications", [
      "unread_notify",
      "notifications",
      "read_more",
    ]),
    classLang() {
      return {
        "ml-5": this.$vuetify.rtl,
        "mr-5": !this.$vuetify.rtl,
      };
    },
    styleDropDown() {
      return {
        "left:125px": this.$vuetify.rtl,
        "left:225px": !this.$vuetify.rtl,
      };
    },
    fullName() {
      return this.user ? this.user.first_name + this.user.last_name : "";
    },
    accountType() {
      return this.user.type ? this.user.type : "";
    },
  },
  methods: {
    ...mapActions("notifications", [
      "increaseUnreadMessage",
      "seeMoreNotifications",
    ]),
    ...mapMutations("notifications", ["SET_COUNT_UNREAD_MESSAGE"]),
    clear_notification() {
      if (this.unread_notify > 0)
        this.$http
          .post("general/notification/clear_notification")
          .then((response) => {
            this.SET_COUNT_UNREAD_MESSAGE(0);
          });
    },
    see_notify(item) {
      this.$http
        .get("general/notification/make_notification_seen/" + item.id)
        .then((response) => {
          if (item.is_seen == 0) {
            item.is_seen = 1;
            this.increaseUnreadMessage();
          }
          window.location = item.link;
          // if (this.$route.path != item.link) this.$router.push(item.link);
        });
    },
    read_more_method() {
      this.count++;
      this.seeMoreNotifications(this.count);
    },
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then((response) => {
          console.log(response);
          this.$router.push("/login");
        })
        .catch((err) => console.log(err));
    },
    change_lang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      if (this.$vuetify.breakpoint.mobile) {
        this.drawerClose();
        return;
      }
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
<style scoped>
.see_more.v-list-item {
  padding: 0 !important;
}
</style>
