
class AuthService {

    login(user) {
        return axios
            .post('auth/login', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                var data = response.data.data.original
                console.log(data)
                if (data.access_token) {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    localStorage.setItem('accessToken', JSON.stringify(data.access_token));
                }
                return data;
            });
    }

    logout() {

        return axios.post('auth/logout')
            .then(resp => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                return resp
            })
            .catch(err => {
                localStorage.removeItem('accessToken')
                localStorage.removeItem('user')
                return err
            })
    }
    register(user) {
        return axios.post('auth/register', user);
    }
    permission() {
        return axios.get('auth/permission-me');
    }
    reset_password(email) {
        return axios.post('auth/password-reset-request', email)
    }
    new_password(data) {
        return axios.post('auth/password-reset', data)
    }
}
export default new AuthService();