import Vue from "vue";
import VueRouter from "vue-router";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
import BlankLayout from "../views/Layout/BlankLayout";
import Middleware from "../middleware";


import {
    PageError,
    others,
    vrPages,
    profilePages,
    usersPages,
    accountPages,
    projectsPages,
    applicationPages,
    ecommercePages,
    pricingPage,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
} from "./tamplate";


import dashboard from "./dashboard"
let dashboardPages = dashboard;
import { projects, daily_entry, requests } from "./basic"
import { admin_report, contractor_report } from "./report"
import auth from "./auth"
let authPages = auth;

import {
    roles, users,
    employee
    , contractors
} from "./user"

Vue.use(VueRouter);
let FilePage = {
    path: "/",
    component: BlankLayout,
    name: "FileInvoiceRequestPage",
    children: [{
        path: "/requests/invoice/:id",
        name: "InvoiceRequests",
        component: () =>
            import("@/views/Current/Requests/invoice.vue"),
        meta: {
            groupName: "requests",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: '',
        },
    },
    ],
};

const routes = [
    dashboardPages,
    FilePage,
    others,
    usersPages,
    PageError,
    pricingPage,
    profilePages,
    applicationPages,
    ecommercePages,
    accountPages,
    projectsPages,
    vrPages,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
    authPages,
    roles,
    users,
    contractors,
    employee,
    projects,
    daily_entry,
    contractor_report,
    admin_report,
    requests,
    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [{
            path: "/404",
            name: "404",
            component: () =>
                import("@/views/Error/404.vue"),
        },]
    },
    //     {
    //     path: '*',
    //     component: () =>
    //         import("@/views/Error/404.vue"),
    // }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;