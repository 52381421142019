import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import "@mdi/font/css/materialdesignicons.css";
import store from "./store";
import i18n from "./i18n";
import middleware from "./middleware/";
import "./http/httpClient";

Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

// Axios setup
import VueAxios from 'vue-axios'


import authHeader from './services/auth-header';
const axios = require('axios')
Vue.use(VueAxios, axios)

import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
window.axios = require('axios')

axios.interceptors.request.use(function (config) {
    config.headers = authHeader()
    return config
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error)
    if (error.response.status == 401) {
        console.log('401')
        store.dispatch("auth/logout")
            .then(() => {

                // router.push(localStorage.getItem('login_page'))
            })
            .catch((err) => console.log(err));
    }
    else if (error.response.status == 500) {
        console.log('500')
        if (error.response.data.code && error.response.data.code == '401') {
            console.log('test 401')
        } else {
            // store.dispatch("form/setNotify", {
            //     msg: i18n.t("general.there is problem"),
            //     type: "Danger",
            // });
        }
    }
    return Promise.reject(error);
});

if (localStorage.hasOwnProperty('lang')) {
    var lang = localStorage.getItem("lang")
} else {
    var lang = localStorage.setItem("lang", 'ar');
}
document.documentElement.lang = lang

import moment from 'moment';
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('Y-m-d hh:mm:ss')
    }
});

Vue.filter('getMonth', function (value) {
    if (value) {
        const date = new Date();
        date.setMonth(value - 1);
        return date.toLocaleString([], {
            month: 'long',
        });
    }
});

// Vue.prototype.$setupTimers = setupTimers;

require('./assets/css/fonts.css');
require('./assets/css/main.css');

function nextCheck(ctx, middleware, index, store) {
    const nextMiddleware = middleware[index];
    if (!nextMiddleware) return ctx.next;

    return (...params) => {
        ctx.next(...params);
        const nextMidd = nextCheck(ctx, middleware, index + 1, store);
        nextMiddleware({ ...ctx, nextMiddleware: nextMidd, store, contextNext: ctx.next });
    };
}

// Auth && Permissions routes setup
router.beforeEach((to, from, next) => {
    if (store.dispatch("ResetData")) {
        if (to.meta.middlewares) {
            const middleware = Array.isArray(to.meta.middlewares)
                ? to.meta.middlewares
                : [to.meta.middlewares];
            const ctx = { from, next, router, to };

            const nextMiddleware = nextCheck(ctx, middleware, 1, store);
            return middleware[0]({ ...ctx, nextMiddleware: nextMiddleware, store, contextNext: ctx.next });
        }

        return next();
    }
});


new Vue({
    router,
    vuetify,
    store,
    i18n,
    icons: {
        iconfont: 'mdi',
    },
    render: (h) => h(App),
}).$mount("#app");