
class ProjectService {
  get_projects(page, data) {
    return axios.post('projects?page=' + page, data)
  }
  edit(id) {
    return axios.get('projects/edit/' + id)
  }
  store(data) {
    return axios.post('projects/store', data)
  }
  update(id, data) {
    return axios.post('projects/update/' + id, data)
  }
  destroy(id) {
    return axios.delete('projects/delete/' + id)
  }
  single_project(id) {
    return axios.get('projects/get-single-project/' + id)
  }


  create() {
    return axios.get('projects/create')
  }

  store_work(data) {
    return axios.post('project-work/store', data);
  }

  update_work(id, data) {
    return axios.post('project-work/update/' + id, data);
  }

  delete_work(id) {
    return axios.delete('project-work/delete/' + id);
  }

  get_works_employee(id) {
    return axios.get('/projects/get-work-employee/' + id);
  }

  assign_employee(id, data) {
    return axios.post('projects/assign-employee/' + id, data)
  }

  details(id) {
    return axios.get('projects/details/' + id)
  }

  change_status(id, data) {
    return axios.post('projects/change-status/' + id, data)
  }

}
export default new ProjectService();