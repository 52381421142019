import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const admin_report = {
    path: "/",
    component: DashboardLayout,
    name: "report-Managment",
    children: [{
        path: "/reports/daily",
        name: "dailyReportAdmin",
        component: () =>
            import("@/views/Current/Reports/daily.vue"),
        meta: {
            groupName: "Reports",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'report-daily-index',
        },
    },
    {
        path: "/reports/projects",
        name: "monthlyProjectReport",
        component: () =>
            import("@/views/Current/Reports/project.vue"),
        meta: {
            groupName: "Reports",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'report-monthly-project',
        },
    },
    {
        path: "/reports/employee",
        name: "monthlyEmployeeReport",
        component: () =>
            import("@/views/Current/Reports/employee.vue"),
        meta: {
            groupName: "Reports",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'report-monthly-employee',
        },
    },
    ]
}


export const contractor_report = {
    path: "/",
    component: DashboardLayout,
    name: "ReportContractorManagment",
    children: [{
        path: "/reports/daily",
        name: "dailyReportContractor",
        component: () =>
            import("@/views/Current/Reports/daily.vue"),
        meta: {
            groupName: "Reports",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: '',
        },
    },
    ]
}