export const projects = {
    action: "mdi-city",
    title: "Projects",
    permission: "project",
    items: [
        {
            prefix: "O",
            title: "showprojects",
            link: "/projects",
            permission: "project-list",
        },
        {
            prefix: "O",
            title: "CreateProject",
            link: "/projects/create",
            permission: "project-create",
        },
    ]
}

export const daily_entry = {
    action: "mdi-account-arrow-left",
    title: "daily_entry",
    permission: "delaiy-entries",
    items: [

        {
            prefix: "O",
            title: "daily entry show",
            link: "/daily-entry",
            permission: "delaiy-entries-index",
        },
        {
            prefix: "O",
            title: "CreateDailyEntry",
            link: "/daily-entry/create",
            permission: "delaiy-entries-create",
        },

    ]
}

export const requests = {
    action: "mdi-account-arrow-left",
    title: "Requests",
    permission: "request",
    items: [

        {
            prefix: "O",
            title: "Requests",
            link: "/requests",
            permission: "request-index",
        },
        {
            prefix: "O",
            title: "CreateRequest",
            link: "/requests/create",
            permission: "request-create",
        },

    ]
}