import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
// Dashboard pages
const Dashboard = () =>
    import("../views/Dashboard/Dashboard.vue");
const Automotive = () =>
    import("../views/Dashboard/Automotive.vue");
const SmartHome = () =>
    import("../views/Dashboard/SmartHome.vue");

const Crm = () =>
    import("../views/Dashboard/Crm.vue");
export default {
    path: "/",
    name: "Dashboard",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [{
        path: "dashboard",
        name: "Default",
        component: Dashboard,
        meta: {
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            groupName: "Dashboard",
            permissions: '',
        },
    },
    {
        path: "/pages/dashboards/automotive",
        name: "Automotive",
        component: Automotive,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/smart-home",
        name: "SmartHome",
        component: SmartHome,
        meta: {
            groupName: "Dashboards",
        },
    },
    {
        path: "/pages/dashboards/crm",
        name: "Crm",
        component: Crm,
        meta: {
            groupName: "Components",
        },
    },
    ]
}